import { BrowserAuthOptions } from '@azure/msal-browser';
import { EnvironmentType } from 'src/app/shared/components/environmental-indicator/environmental-indicator.component';

const appRegistration = {
  clientId: '5345cc57-9c86-4f33-8b68-869f16a657f9',
  tenantId: 'fd799da1-bfc1-4234-a91c-72b3a1cb9e26',
};

const msalConfigBrowserAuth: BrowserAuthOptions = {
  clientId: appRegistration.clientId,
  redirectUri: location.origin,
  postLogoutRedirectUri: location.origin,
  authority: `https://login.microsoftonline.com/${appRegistration.tenantId}/`,
  navigateToLoginRequestUrl: true,
};

export const environment = {
  msalConfig: {
    browserAuth: msalConfigBrowserAuth,
  },
  coatingsApiEndpoint: 'https://esra2-coatings-api-test.azure.chevron.com',
  weldingApiEndpoint: 'https://esra2-welding-api-test.azure.chevron.com',
  pipingApiEndpoint: 'https://esra2-piping-api-test.azure.chevron.com',
  appRegistration: appRegistration,
  production: false,
  environmentType: EnvironmentType.TEST,

  appInsightUrl: "https://api.applicationinsights.io/v1/apps/",
  appInsightId: "e8ca58db-a58a-481f-965d-1464a5113b76",
  instrumentationKey: 'b0d7fb51-60dc-4f91-89de-cd701173340c'
};
